import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Field as IField, FieldType } from '../../../../../types';
import { useFieldLabel } from '../../../../../contexts/widgets';
import { classes } from './Field.st.css';

interface FieldValueProps {
  id: IField['id'];
  type: IField['type'];
  value: NonNullable<IField['value']>;
}

const FieldValue: FC<FieldValueProps> = ({ id, type, value }) => {
  if (type === FieldType.URL) {
    return (
      <>
        {value.map((href, index) => (
          <a
            key={`${id}-${index}`}
            className={classes.link}
            href={href as string}
            rel="noreferrer"
            target="_blank"
          >
            <Text className={classes.value} tagName="div">
              {value}
            </Text>
          </a>
        ))}
      </>
    );
  }

  return (
    <>
      {value.map((text, index) => (
        <Text key={`${id}-${index}`} className={classes.value} tagName="div">
          {text}
        </Text>
      ))}
    </>
  );
};

export const Field: FC<{ field: IField }> = ({ field }) => {
  const { t } = useTranslation();
  const { getFieldLabel } = useFieldLabel();

  return (
    <>
      <Text className={classes.label} tagName="div">
        {getFieldLabel(field.origin, field.key, field.name)}
      </Text>
      {field.value ? (
        <FieldValue id={field.id} type={field.type} value={field.value} />
      ) : (
        <Text className={classes.placeholder} tagName="div">
          {t('about.widget.not-published')}
        </Text>
      )}
    </>
  );
};
