import React, { FC } from 'react';
import { Divider } from 'wix-ui-tpa/cssVars';
import {
  type TFunction,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { DataHook, Experiment } from '../../../../types';
import { useMember } from '../../../../contexts/widgets';
import settingsParams from '../../settingsParams';
import SectionTitle from '../common/SectionTitle';
import StatisticalUnit from './StatisticalUnit';
import About from './About';
import { useAboutRendered, useNumbersVisibility } from './hooks';
import { classes } from './Description.st.css';

type GetTitleProps = {
  count: number;
  singular: string;
  plural: string;
};

const getNonICUTitle = ({ count, singular, plural }: GetTitleProps) => {
  return count > 1 ? plural : singular;
};

const getLikesTitle = (t: TFunction, withICU: boolean, count: number) => {
  return withICU
    ? t('profile-stats-likes-received-icu', {
        count,
      })
    : getNonICUTitle({
        singular: t('profile-stats-like-received'),
        plural: t('profile-stats-likes-received'),
        count,
      });
};

const getCommentsTitle = (t: TFunction, withICU: boolean, count: number) => {
  return withICU
    ? t('profile-stats-comments-icu', {
        count,
      })
    : getNonICUTitle({
        count,
        singular: t('profile-stats-comment'),
        plural: t('profile-stats-comments'),
      });
};

const getTopCommentsTitle = (t: TFunction, withICU: boolean, count: number) => {
  return withICU
    ? t('profile-stats-top-comments-icu', {
        count,
      })
    : getNonICUTitle({
        count,
        singular: t('profile-stats-top-comment'),
        plural: t('profile-stats-top-comments'),
      });
};

export const Description: FC = () => {
  const settings = useSettings();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { numbers } = useMember();
  const { shouldRenderAbout } = useAboutRendered();
  const numbersVisibility = useNumbersVisibility();
  const { experiments } = useExperiments();

  const shouldRenderStats = Object.values(numbersVisibility).some(Boolean);
  const shouldUseICU = experiments.enabled(Experiment.EnablePluralStatisticFix);

  return (
    <>
      <SectionTitle>
        {settings.get(settingsParams.aboutSectionTitle)}
      </SectionTitle>
      {shouldRenderStats && (
        <div className={classes.statsContainer}>
          {numbersVisibility.showLikesReceivedStats && (
            <StatisticalUnit
              dataHook={DataHook.LikesReceivedStats}
              className={classes.statisticalUnit}
              tooltipText={t('profile-stats-likes-received-tooltip')}
              count={numbers.likesReceived}
              title={getLikesTitle(t, shouldUseICU, numbers.likesReceived)}
            />
          )}
          {numbersVisibility.showCommentsStats && (
            <StatisticalUnit
              dataHook={DataHook.CommentsStats}
              className={classes.statisticalUnit}
              tooltipText={t('profile-stats-comments-tooltip')}
              count={numbers.commentsReceived}
              title={getCommentsTitle(
                t,
                shouldUseICU,
                numbers.commentsReceived,
              )}
            />
          )}
          {numbersVisibility.showTopCommentsStats && (
            <StatisticalUnit
              dataHook={DataHook.TopCommentsStats}
              className={classes.statisticalUnit}
              tooltipText={t('profile-stats-top-comments-tooltip')}
              count={numbers.topCommentsCount}
              title={getTopCommentsTitle(
                t,
                shouldUseICU,
                numbers.topCommentsCount,
              )}
            />
          )}
        </div>
      )}
      {shouldRenderStats && shouldRenderAbout && !isMobile && (
        <Divider className={classes.divider} />
      )}
      {shouldRenderAbout && <About />}
    </>
  );
};
