import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { classes } from './EmptyState.st.css';

export const EmptyState: FC = () => {
  const { t } = useTranslation();

  return (
    <Text className={classes.root}>
      {t('about.widget.overview-empty-state')}
    </Text>
  );
};
