import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useSettings } from '@wix/tpa-settings/react';

import settingsParams from '../../../../settingsParams';
import CameraSmall from './CameraSmall';
import VideoSmall from './VideoSmall';
import GifSmall from './GifSmall';
import { classes } from './EmptyEditor.st.css';

export const EmptyEditor: FC = () => {
  const settings = useSettings();

  return (
    <div className={classes.root}>
      <Text tagName="div" className={classes.placeholder}>
        {settings.get(settingsParams.aboutDescriptionPlaceholder)}
      </Text>
      <div className={classes.footer}>
        <CameraSmall className={classes.icon} />
        <VideoSmall className={classes.icon} />
        <GifSmall className={classes.icon} />
      </div>
    </div>
  );
};
