import React, { FC } from 'react';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa/cssVars';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { DataHook, Experiment } from '../../../../../types';
import {
  useBlogPosts,
  useCanEditMember,
} from '../../../../../contexts/widgets';
import settingsParams from '../../../settingsParams';
import SectionTitle from '../../common/SectionTitle';
import { useCreatePostClickHandler } from '../hooks';
import { classes } from './Title.st.css';

interface TitleProps {
  postCount: number;
}

export const Title: FC<TitleProps> = ({ postCount }) => {
  const settings = useSettings();
  const { t } = useTranslation();
  const { canEditMember } = useCanEditMember();
  const { createBlogPostUrl } = useBlogPosts();
  const { handleCreatePostLinkClick } = useCreatePostClickHandler();
  const { experiments } = useExperiments();

  const showPostCount =
    settings.get(settingsParams.showBlogPostsCounter) && postCount > 0;
  const isBlogLiveSiteEditingEnabled = !experiments.enabled(
    Experiment.BlogLiveSiteEditingDeprecation,
  );

  return (
    <div className={classes.root}>
      <SectionTitle>
        {settings.get(settingsParams.blogPostsTitle)}
        {showPostCount && ` (${postCount})`}
      </SectionTitle>
      {canEditMember && isBlogLiveSiteEditingEnabled && (
        <TextButton
          data-hook={DataHook.CreatePostLink}
          className={classes.createPostLink}
          as="a"
          href={createBlogPostUrl?.url}
          priority={TextButtonPriority.primary}
          onClick={handleCreatePostLinkClick}
        >
          {t('recent-posts.create-post')}
        </TextButton>
      )}
    </div>
  );
};
